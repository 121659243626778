<template>
    <div>
        <base-header :base-title="headTitle"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title :head-title="headTitle"></base-title>
                    <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <!-- <auto-refresh
                :table-name="'table_whatsapp_history'"
                :on-refresh="fetch"
              ></auto-refresh>&nbsp;&nbsp; -->
                        </div>
                    </div>
                </div>
                <div class="kt-portlet__body">
                    <!-- <data-tables :table="table"></data-tables> -->
                    <v-server-table
                        :columns="columns"
                        :options="options"
                        ref="table"
                        @loaded="onLoaded(0)"
                        @loading="onLoaded(1)"
                    >
                        <div slot="action" slot-scope="{ row }">
                            <button
                                @click="showData(row)"
                                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                                title="view"
                            >
                                <i class="la la-eye"></i>
                            </button>
                        </div>
                        <div v-if="isLoading" slot="afterBody">
                            <h6 class="txt">loading..</h6>
                        </div>
                        <div slot="created_at" slot-scope="{ row }">
                            <span class="p-2">{{
                                row.created_at | changeTime
                            }}</span>
                            <br />
                        </div>
                        <div slot="updated_at" slot-scope="{ row }">
                            <span class="p-2">{{
                                row.updated_at | changeTime
                            }}</span>
                            <br />
                        </div>
                    </v-server-table>
                </div>

                <template v-if="isModalWhatsapp">
                    <form-modal-whatsapp
                        :id-whatsapp="idSelected"
                    ></form-modal-whatsapp>
                </template>
                <template v-if="isModalEmail">
                    <form-modal-email :id-email="idSelected"></form-modal-email>
                </template>
                <template v-if="isModalFCM">
                    <form-modal-fcm :id-fcm="idSelected"></form-modal-fcm>
                </template>
                <template v-if="isModalSMS">
                    <form-modal-sms ></form-modal-sms>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./../../../components/_base/BaseTitle";
import BaseHeader from "./../../../components/_base/BaseHeader";
// import LoadSpinner from "./../../../components_general/LoadSpinner";
import { RepositoryFactory } from "../../repositories/BaseRepositoryFactory";
const MasterRepository = RepositoryFactory.get("HistoryNotification");
import { mapState, mapMutations } from "vuex";
import LoadSpinner from "./../../../components/_general/LoadSpinner";

export default {
    components: {
        BaseTitle,
        BaseHeader,
        FormModalWhatsapp: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import("./../../../components/whatsapp_histories/_components/FormModal.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormModalEmail: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import("./../../../components/email_histories/_components/FormModal.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormModalSms: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import("./../../../components/sms_histories/_components/FormModal.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        }),
        FormModalFcm: () => ({
            component: new Promise(function(resolve, reject) {
                setTimeout(function() {
                    resolve(
                        import("./../../../components/fcm_histories/_components/FormModal.vue")
                    );
                }, 1000);
            }),
            loading: LoadSpinner
        })
    },
    data() {
        var vx = this;
        return {
            idSelected: "",
            isShowData: false,
            detailWhatsapp: {},
            columns: [
                "id",
                "created_at",
                "updated_at",
                "recipient",
                "deliveryStatus",
                "sendStatus",
                "channel",
                "action"
            ],
            options: {
                texts: {
                    filterBy: "Cari"
                },
                listColumns: {
                    sendStatus: [
                        {
                            id: "SUCCESS",
                            text: "SUCCESS"
                        },
                        {
                            id: "INVALID_PARAM",
                            text: "INVALID_PARAM"
                        },
                        {
                            id: "INTERNAL_ERROR",
                            text: "INTERNAL_ERROR"
                        },
                        {
                            id: "INVALID_DESTINATION",
                            text: "INVALID_DESTINATION"
                        },
                        {
                            id: "IP_IS_NOT_WHITELIST",
                            text: "IP_IS_NOT_WHITELIST"
                        },
                        {
                            id: "MEMBERSHIP_IS_NOT_AVAILABLE",
                            text: "MEMBERSHIP_IS_NOT_AVAILABLE"
                        },
                        {
                            id: "NOT_ENOUGH_CREDIT",
                            text: "NOT_ENOUGH_CREDIT"
                        },
                        {
                            id: "INVALID_USER_PASSWORD",
                            text: "INVALID_USER_PASSWORD"
                        }
                    ],
                    channel: [
                        {
                            id: "WA",
                            text: "WA"
                        },
                        {
                            id: "EMAIL",
                            text: "Email"
                        },
                        {
                            id: "FCM",
                            text: "FCM"
                        },
                        {
                            id: "SMS",
                            text: "SMS"
                        }
                    ]
                },
                filterable: [
                    "id",
                    "created_at",
                    "updated_at",
                    "recipient",
                    "deliveryStatus",
                    "sendStatus",
                    "channel"
                ],
                headings: {
                    id: "ID",
                    created_at: "Tgl Buat",
                    updated_at: "Tgl Update",
                    recipient: "Penerima",
                    sendStatus: "Send Status",
                    deliveryStatus: "Delivery Status",
                    channel: "Channel",
                    action: "Actions"
                },
                dateColumns: ["created_at", "updated_at"],
                debounce: 500,
                filterByColumn: true,
                toMomentFormat: "YYYY-MM-DD",
                requestFunction(data) {
                    let params = {};
                    let page = {};
                    page.sort = "created_at,desc";
                    page.page = data.page - 1;
                    page.size = data.limit;
                    this.isLoaded = false;
                    page["templateChannel.equals"] = vx.$route.params.id;
                    if (data.orderBy) {
                        let orderBy = data.orderBy;
                        page.sort = `${orderBy},${
                            data.ascending == "1" ? "desc" : "asc"
                        }`;
                    }
                    if (_.isEmpty(data.query)) {
                        return MasterRepository.fetchHistoryNotif(page).catch(
                            function(e) {
                                // this.dispatch('error', e);
                                vx.showToastError("Error Get Data");
                            }
                        );
                        1;
                    } else {
                        // params.query = helpers.filterWithKey(data.query);
                        params.query = helpers.filterSMSTable(data.query);
                        if (data.query.channel) {
                            page["channel.in"] = data.query.channel;
                        }
                        const query = { ...page, ...params.query };
                        return MasterRepository.fetchHistoryNotif(query).catch(
                            function(e) {
                                vx.showToastError("Error Get Data");
                                // this.dispatch('error', e);
                            }
                        );
                    }
                },
                responseAdapter: resp => {
                    return {
                        data: resp.data,
                        count: resp.headers["x-total-count"]
                            ? resp.headers["x-total-count"]
                            : resp.headers["Content-Length"]
                            ? resp.headers["Content-Length"]
                            : resp.data.length
                    };
                },
                rowClassCallback(row) {
                    return row.isNewData ? "highlight" : "";
                },
                sortIcon: {
                    base: "fa",
                    is: "fa-sort",
                    up: "fa-sort-up",
                    down: "fa-sort-down "
                },
                filterByColumn: true,
                sortable: [
                    "id",
                    "created_at",
                    "updated_at",
                    "recipient",
                    "sendStatus",
                    "deliveryStatus"
                ],
                templates: {
                    // createdDate(h, row) {
                    //     return moment(row.createdDate).add(7, "hours").format("DD-MMM-YYYY");
                    // },
                    // authorities(h, row) {
                    //     let text = '<span class="p-2 mt-1 badge badge-info">'+row.authorities+'</span> <br/>'
                    //     return row.authorities
                    // },
                    // activated(h, row) {
                    //     return row.activated == true ? 'True' : 'False'
                    // },
                }
            },
            isLoading: false,
            headTitle: this.$route.params.id
        };
    },
    destroyed() {
        this.changeShowModalWA(false);
        this.changeShowModalEmail(false);
        this.changeShowModalFCM(false);
         this.changeShowModalSMS(false)
    },
    computed: {
        ...mapState("MasterStore", {
            isLoaded: state => state.isLoaded
        }),
        ...mapState("NotifikasiStore", {
            isModalWhatsapp: state => state.isModalWhatsapp,
            isModalEmail: state => state.isModalEmail,
            isModalFCM: state => state.isModalFCM,
            isModalSMS: state => state.isModalSMS
        })
    },
    filters: {
        changeTime(val) {
            if (val) {
                return moment(val).format("DD-MMM-YYYY HH:mm:ss");
            }
            return "-";
        }
    },
    methods: {
        ...mapMutations({
            changeShowModalWA: "NotifikasiStore/SET_MODAL_WHATSAPP",
            changeShowModalEmail: "NotifikasiStore/SET_MODAL_EMAIL",
            changeShowModalFCM: "NotifikasiStore/SET_MODAL_FCM",
            changeShowModalSMS: "NotifikasiStore/SET_MODAL_SMS",
            setEditData: 'MasterStore/SET_EDIT_DATA'
        }),

        onLoaded: function(t) {
            t == 0 ? (this.isLoading = false) : (this.isLoading = true);
        },
        showToastError: function(message) {
            toastr.error(message);
        },
        async showData(item) {
            // console.log("cekk", item);
            this.idSelected = item.id;
            // this.changeShowModal(true);

            if (item.channel == "Email") {
                this.changeShowModalEmail(true);
            } else if (item.channel == "SMS") {
                this.setEditData(item)
                this.changeShowModalSMS(true)
            } else if (item.channel == "WA") {
                this.changeShowModalWA(true);
            } else if (item.channel == "FCM") {
                this.changeShowModalFCM(true);
            }
        }
    },
    mounted() {
        this.changeShowModalWA(false);
        this.changeShowModalEmail(false);
        this.changeShowModalFCM(false);
         this.changeShowModalSMS(false)
    }
};
</script>
